.sliders-image-max-height {
  max-height: 1200px;
}

.carousel-caption {
  top: 30% !important;
  right: 0% !important;
  left: 25px !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  text-align: unset !important;
}

.section1-dynamic-size-title {
  font-size: 72px;
  line-height: 100%;
  font-family: "aktiv-grotesk-extended-800";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
}

.section1-dynamic-size-subtitle {
  font-size: 20px;
  line-height: 130%;
  font-family: "aktiv-grotesk-400";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  white-space: pre;
}

@media (max-width: 300px) {
  .carousel-caption {
    top: unset !important;
    bottom: 3% !important;
    right: 0% !important;
    left: 0% !important;
    text-align: unset !important;
  }

  .section1-dynamic-size-title {
    font-size: 22px;
    line-height: 100%;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }

  .section1-dynamic-size-subtitle {
    font-size: 16px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    white-space: unset;
  }
}


@media (min-width: 301px) and (max-width: 767px) {
  .carousel-caption {
    top: 45% !important;
    bottom: 3% !important;
    right: 0% !important;
    left: 0% !important;
    text-align: unset !important;
  }

  .section1-dynamic-size-title {
    font-size: 22px;
    line-height: 100%;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }

  .section1-dynamic-size-subtitle {
    font-size: 16px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    white-space: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .carousel-caption {
    top: 20% !important;
    right: 0% !important;
    left: 25px !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    text-align: unset !important;
  }

  .section1-dynamic-size-title {
    font-size: 38px;
    line-height: 100%;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }

  .section1-dynamic-size-subtitle {
    font-size: 12px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .sliders-image-max-height {
    min-height: 700px;
  }

  .carousel-caption {
    top: 18% !important;
    right: 0% !important;
    left: 25px !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    text-align: unset !important;
  }

  .section1-dynamic-size-title {
    font-size: 65px;
    line-height: 100%;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }

  .section1-dynamic-size-subtitle {
    font-size: 18px;
    line-height: 130%;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }
}

@media (min-width: 1281px) and (max-width: 1500px) {

  .carousel-caption {
    top: 30% !important;
    right: 0% !important;
    left: 25px !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    text-align: unset !important;
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }
}

@media (min-width: 1501px) and (max-width: 1750px) {

  .carousel-caption {
    top: 40% !important;
    right: 0% !important;
    left: 25px !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    text-align: unset !important;
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }
}


@media (min-width: 1751px){

  .carousel-caption {
    top: 40% !important;
    right: 0% !important;
    left: 25px !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    text-align: unset !important;
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  }
}

@import "src/styles/index.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &--header {
    margin-bottom: 5.75rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--body {
    background-color: var(--color-f7f7f7);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &--footer {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}

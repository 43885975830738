.header-navbar {
  position: absolute;
  width: 100%;
  z-index: 101;
  background-color: white;
  top: 0;
  display: flex;
  align-items: center;
  height: 5.75rem;
  justify-content: space-between;
  padding: 0 3.25rem;
  box-shadow: 0px 0.6rem 1.25rem var(--color-00000014);
  pointer-events: unset;
}

.header-navbar-landing {
  position: absolute;
  width: 100%;
  z-index: 101;
  top: 0;
  display: flex;
  align-items: center;
  height: 5.75rem;
  justify-content: space-between;
  padding: 0 3.25rem;
  pointer-events: unset;
}

.header-logo {
  cursor: pointer;
}

.header-languages {
  padding: 0 !important;
  margin: 0 !important;
  background-color: unset !important;
}

.header-selected-language {
  opacity: 1;
  text-decoration: underline;
}

.header-unselected-language {
  opacity: 0.5;
}

.header-child {
  &:last-child {
    padding-top: 3px;
  }
  &:last-child > :last-child {
    display: none;
  }
}

.header-separator {
  display: flex;
  align-items: center;
  margin: 0 0.8rem;
}

@media (max-width: 810px) {
  .header-navbar {
    padding: 0 1.6rem;
  }

  .header-navbar-landing {
    padding: 0 1.6rem;
  }

  .header-separator {
    display: flex;
    align-items: center;
    margin: 0 0.3rem;
  }
}

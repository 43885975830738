.landing-over-position {
  position: absolute;
  top: 0;
  width: 100%;
}

.white-space-pre-subtitle {
  white-space: pre-line;
}

.landing-bg {
  background: #f5f5f5;
}

.section4-bg {
  background: white;
  padding-top: 6rem;
}

.section5-bg {
  background: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section6-bg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section7-bg {
  background: white;
  padding-top: 6rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.section8-bg {
  background: white;
  padding-top: 6rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

@media (max-width: 400px){
  .margin-from-carousel {
    margin-top: 630px;
  }

  .white-space-pre-subtitle {
    white-space: unset;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 401px) and (max-width: 498px){
  .margin-from-carousel {
    margin-top: 700px;
  }

  .white-space-pre-subtitle {
    white-space: unset;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}


@media (min-width: 499px) and (max-width: 624px){
  .margin-from-carousel {
    margin-top: 1000px;
  }

  .white-space-pre-subtitle {
    white-space: unset;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 625px) and (max-width: 767px){
  .margin-from-carousel {
    margin-top: 1200px;
  }

  .white-space-pre-subtitle {
    white-space: unset;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .margin-from-carousel {
    margin-top: 450px;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}



@media (min-width: 992px) and (max-width: 1280px) {
  .margin-from-carousel {
    margin-top: 750px;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1281px) and (max-width: 1500px) {
  .margin-from-carousel {
    margin-top: 750px;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1501px) and (max-width: 1750px) {
  .margin-from-carousel {
    margin-top: 900px;
  }

  .section7-bg {
    background: white;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section8-bg {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1751px) {
  .margin-from-carousel {
    margin-top: 1100px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.white-space-pre {
  white-space: pre-line;
}

.display-contents {
  display: contents;
}

.max-w-fit-content {
  max-width: fit-content;
}

.border-underline-link-000000 {
  border-bottom: 1px solid var(--color-000000) !important;
}

.border-underline-link-862633 {
  border-bottom: 1px solid var(--color-862633) !important;
}

.border-underline-link-ffea4f-title {
  border-bottom: 4px solid var(--color-ffea4f) !important;
}

.border-underline-link-ffea4f {
  border-bottom: 2px solid var(--color-ffea4f) !important;
}

.d-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.line-over {
  text-decoration: line-through;
  text-decoration-style: solid;
}

.text-upp-placeholder-low {
  input {
    text-transform: uppercase;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none !important;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none !important;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none !important;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none !important;
  }
  ::placeholder {
    /* Recent browsers */
    text-transform: none !important;
  }
}

.delete-last-accordion-border:last-child {
  &:last-child > :last-child > :last-child {
    display: none;
  }
}

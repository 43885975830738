:root {
  --font-family-sofia-pro-black-italic: "SofiaPro-Blackitalic";
  --font-family-sofia-pro-black: "SofiaPro-Black";
  --font-family-sofia-pro-bold-italic: "SofiaPro-Bolditalic";
  --font-family-sofia-pro-bold: "SofiaPro-Bold";
  --font-family-sofia-pro-extralight-italic: "SofiaPro-ExtraLightitalic";
  --font-family-sofia-pro-extralight: "SofiaPro-ExtraLight";
  --font-family-sofia-pro-light-italic: "SofiaPro-Lightitalic";
  --font-family-sofia-pro-light: "SofiaPro-Light";
  --font-family-sofia-pro-medium-italic: "SofiaPro-Mediumitalic";
  --font-family-sofia-pro-medium: "SofiaPro-Medium";
  --font-family-sofia-pro-regular-italic: "SofiaPro-Regularitalic";
  --font-family-sofia-pro-semibold-italic: "SofiaPro-SemiBolditalic";
  --font-family-sofia-pro-semibold: "SofiaPro-SemiBold";
  --font-family-sofia-pro: "SofiaPro-Regular";

  --font-family-aktiv-grotesk-400: "aktiv-grotesk-400";
  --font-family-aktiv-grotesk-500: "aktiv-grotesk-500";
  --font-family-aktiv-grotesk-extended-500: "aktiv-grotesk-extended-500";
  --font-family-aktiv-grotesk-extended-700: "aktiv-grotesk-extended-700";
  --font-family-aktiv-grotesk-extended-800: "aktiv-grotesk-extended-800";

  
  --font-size-xxs: 0.7rem;
  --font-size-xs: 0.8rem;
  --font-size-s: 0.9rem;
  --font-size-m: 1rem;
  --font-size-l: 1.1rem;
  --font-size-xl: 1.2rem;
  --font-size-xxl: 1.3rem;

  --font-medium-s: var(--font-size-s) var(--font-family-sofia-pro-medium);
  --font-medium-m: var(--font-size-m) var(--font-family-sofia-pro-medium);
  --font-medium-l: var(--font-size-l) var(--font-family-sofia-pro-medium);

  .font-medium-s {
    font: var(--font-medium-s);
  }

  .font-medium-m {
    font: var(--font-medium-m);
  }

  .font-medium-l {
    font: var(--font-medium-l);
  }
}

$sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
  81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  100;

  
/**
 * Sofia Pro Black Italic
 */
@each $size in $sizes {
  .font-#{$size}-black-italic {
    font-family: var(--font-family-sofia-pro-black-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Black
 */
@each $size in $sizes {
  .font-#{$size}-black {
    font-family: var(--font-family-sofia-pro-black);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Bold Italic
 */
@each $size in $sizes {
  .font-#{$size}-bold-italic {
    font-family: var(--font-family-sofia-pro-bold-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Bold
 */
@each $size in $sizes {
  .font-#{$size}-bold {
    font-family: var(--font-family-sofia-pro-bold);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Extralight Italic
 */
@each $size in $sizes {
  .font-#{$size}-extralight-italic {
    font-family: var(--font-family-sofia-pro-extralight-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Extralight
 */
@each $size in $sizes {
  .font-#{$size}-extralight {
    font-family: var(--font-family-sofia-pro-extralight);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Light Italic
 */
@each $size in $sizes {
  .font-#{$size}-light-italic {
    font-family: var(--font-family-sofia-pro-light-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Light
 */
@each $size in $sizes {
  .font-#{$size}-light {
    font-family: var(--font-family-sofia-pro-light);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Medium Italic
 */
@each $size in $sizes {
  .font-#{$size}-medium-italic {
    font-family: var(--font-family-sofia-pro-medium-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Medium
 */
@each $size in $sizes {
  .font-#{$size}-medium {
    font-family: var(--font-family-sofia-pro-medium);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Regular Italic
 */
@each $size in $sizes {
  .font-#{$size}-regular-italic {
    font-family: var(--font-family-sofia-pro-regular-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Semibold Italic
 */
@each $size in $sizes {
  .font-#{$size}-semibold-italic {
    font-family: var(--font-family-sofia-pro-semibold-italic);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro Semibold
 */
@each $size in $sizes {
  .font-#{$size}-semibold {
    font-family: var(--font-family-sofia-pro-semibold);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Sofia Pro
 */
@each $size in $sizes {
  .font-#{$size} {
    font-family: var(--font-family-sofia-pro);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Aktiv grotesk 400
 */
@each $size in $sizes {
  .font-#{$size}-ag-400 {
    font-family: var(--font-family-aktiv-grotesk-400);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Aktiv grotesk 500
 */
@each $size in $sizes {
  .font-#{$size}-ag-500 {
    font-family: var(--font-family-aktiv-grotesk-500);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Aktiv grotesk extended 500
 */
@each $size in $sizes {
  .font-#{$size}-age-500 {
    font-family: var(--font-family-aktiv-grotesk-extended-500);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Aktiv grotesk extended 700
 */
@each $size in $sizes {
  .font-#{$size}-age-700 {
    font-family: var(--font-family-aktiv-grotesk-extended-700);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

/**
 * Aktiv grotesk extended 800
 */
@each $size in $sizes {
  .font-#{$size}-age-800 {
    font-family: var(--font-family-aktiv-grotesk-extended-800);
    font-size: #{$size}px;
    line-height: #{$size + 10}px;
  }
}

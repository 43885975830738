.text-underline-grey {
  text-decoration: underline rgba(20, 20, 20, 0.7);
  color: rgba(20, 20, 20, 0.7);
}

.section2-dynamic-size-title {
  font-size: 48px;
  font-family: "aktiv-grotesk-extended-800";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 110%;
  white-space: pre;
}

.section2-dynamic-size-subtitle {
  font-size: 20px;
  font-family: "aktiv-grotesk-400";
  transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
  line-height: 130%;
  white-space: pre;
}

.periods-border {
  border-bottom: 1.5px solid #eaeaea;
}

.selected-period-border {
  border-bottom: 2px solid #4145e5;
}


.unselected-period-border {
  background-color: unset;
}

.label-opacity-grey {
  color: rgba(20, 20, 20, 0.7);
}

.section2-card {
  background: #ffffff;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  min-height: 905px;
}

.selected-package {
  background: rgba(65, 69, 229, 0.2);
  border: 2px solid #4145e5;
  border-radius: 4px;
  min-height: 905px;
}

@media (max-width: 767px) {
  .unselected-period-border {
    background-color: #ffffff;
    border-radius: 4px;
  }

  .section2-dynamic-size-title {
    font-size: 24px;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 110%;
    white-space: unset;
  }

  .section2-dynamic-size-subtitle {
    font-size: 16px;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 130%;
    white-space: unset;
  }

  .periods-border {
    border-bottom: unset;
  }

  .selected-period-border {
    border-bottom: unset;
    background: linear-gradient(
        0deg,
        rgba(65, 69, 229, 0.2),
        rgba(65, 69, 229, 0.2)
      ),
      #ffffff;
    border: 1px solid #4145e5;
    border-radius: 4px;
  }

  .section2-card {
    background: #ffffff;
    border: 2px solid #eaeaea;
    border-radius: 4px;
    min-height: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section2-dynamic-size-title {
    font-size: 40px;
    font-family: "aktiv-grotesk-extended-800";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 110%;
    white-space: pre;
  }

  .section2-dynamic-size-subtitle {
    font-size: 20px;
    font-family: "aktiv-grotesk-400";
    transition: font-size 0.1s ease-in-out, line-height 0.1s ease-in-out;
    line-height: 130%;
    white-space: pre;
  }

  .selected-package {
    background: rgba(65, 69, 229, 0.2);
    border: 2px solid #4145e5;
    border-radius: 4px;

    .section2-space-0 {
      min-height: 250px;
    }

    .section2-space-1 {
      border-bottom: 2px solid #eaeaea;
      min-height: 400px;
    }

    .section2-space-2 {
      border-bottom: 2px solid #eaeaea;
      min-height: 400px;
    }

    .section2-space-3 {
      border-bottom: 2px solid #eaeaea;
      min-height: 650px;
    }

    .section2-space-4 {
      border-bottom: 2px solid #eaeaea;
      min-height: 200px;
    }

    .section2-space-5 {
      min-height: 150px;
    }
  }

  .section2-space-0 {
    min-height: 250px;
  }

  .section2-space-1 {
    min-height: 400px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-2 {
    min-height: 400px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-3 {
    min-height: 650px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-4 {
    min-height: 200px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-5 {
    min-height: 150px;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .selected-package {
    background: rgba(65, 69, 229, 0.2);
    border: 2px solid #4145e5;
    border-radius: 4px;

    .section2-space-0 {
      min-height: 200px;
    }

    .section2-space-1 {
      border-bottom: 2px solid #eaeaea;
      min-height: 250px;
    }

    .section2-space-2 {
      border-bottom: 2px solid #eaeaea;
      min-height: 200px;
    }

    .section2-space-3 {
      border-bottom: 2px solid #eaeaea;
      min-height: 350px;
    }

    .section2-space-4 {
      border-bottom: 2px solid #eaeaea;
      min-height: 150px;
    }

    .section2-space-5 {
      min-height: 100px;
    }
  }

  .section2-space-0 {
    min-height: 200px;
  }

  .section2-space-1 {
    min-height: 250px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-2 {
    min-height: 200px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-3 {
    min-height: 350px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-4 {
    min-height: 150px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-5 {
    min-height: 100px;
  }
}

@media (min-width: 1281px) {
  .selected-package {
    background: rgba(65, 69, 229, 0.2);
    border: 2px solid #4145e5;
    border-radius: 4px;

    .section2-space-0 {
      min-height: 200px;
    }

    .section2-space-1 {
      min-height: 200px;
      border-bottom: 2px solid #eaeaea;
    }

    .section2-space-2 {
      min-height: 170px;
      border-bottom: 2px solid #eaeaea;
    }

    .section2-space-3 {
      min-height: 300px;
      border-bottom: 2px solid #eaeaea;
    }

    .section2-space-4 {
      min-height: 120px;
      border-bottom: 2px solid #eaeaea;
    }

    .section2-space-5 {
      min-height: 80px;
    }
  }

  .section2-space-0 {
    min-height: 200px;
  }

  .section2-space-1 {
    min-height: 200px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-2 {
    min-height: 170px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-3 {
    min-height: 300px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-4 {
    min-height: 120px;
    border-bottom: 2px solid #eaeaea;
  }

  .section2-space-5 {
    min-height: 80px;
  }

}

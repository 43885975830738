.input-select {
  &--label {
    margin: 0;
    font: var(--font-medium-s);
  }

  &--input {
    flex: 1;
    align-self: center;
    font: var(--font-medium-m);
  }

  &--menu {
    background-color: white;
  }

  &--option {
    padding: 0;
    &:last-child > :last-child {
      display: none;
    }

    &-value {
      padding: 8px;
      color: var(--color-disabled);
      font: var(--font-medium-s);
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }

  &--separator {
    border: 0.5px solid #0000001a;
  }

  &--field {
    position: relative;
  }

  &--head {
    display: flex;
    justify-content: space-between;
    margin: 0;
    background-color: white;
    padding: 0 12px;
    min-height: 44px;

    &-disabled {
      cursor: not-allowed;
      background-color: #efefef;
      color: var(--color-disabled);
    }
  }

  &--border {
    &-normal {
      border: 0.5px solid #bfbfbf;
    }
    &-error {
      border: 0.5px solid var(--color-error);
    }
    &-warning {
      border: 0.5px solid var(--color-warning);
    }
  }

  &--icon {
    transform: rotate(90deg);
    transition: 0.5s;
    margin: auto;
  }

  &--rotate {
    transform: rotate(-90deg);
  }

  &--z-index {
    z-index: 1000;
  }

  &--error {
    margin: 0;
    padding: 0;
    color: var(--color-error);
    font: var(--font-medium-s);
  }
}

.custom-modal-backgroud-upload {
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 9998;
  margin: 0;
  background-color: #292929;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

@keyframes animateUpload {
  0% {
    bottom: -80px;
  }
  100% {
    bottom: 0;
  }
}

.custom-modal-upload {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  animation: animateUpload 0.5s;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 250px;
}

.custom-input-upload {
  width: "98px";
  height: "98px";
  & input {
    display: none;
  }
}

.custom-label-pointer {
  & label {
    cursor: pointer;
  }
}

.custom-document-border-green {
  border: 1px solid #059881;
  border-color: rgba($color: #059881, $alpha: 0.5);
}
